import React from "react"
import "../styles/styles.scss"
import { Helmet } from "react-helmet"

import LsPageComponent from "../components/lsPageComponent"

const LSPage = () => (
  <div>
       <Helmet title="Security-Alarm-KMU" defer={false}>
      <html lang="de" />
      <meta name="description" content="ALARM-Projekt -- Security Game 05 -- Alles nur geCLOUD -- Hacke und ermittle
zum Thema Cloud" />
      <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU, CEO Fraud, Cloud, hacken" />
  </Helmet>
    <LsPageComponent 
      ls="5" 
      game="ls5"
      test="true"
      questionairs="false"
      motto="Alles nur geCLOUD"
      chapterUrl="https://client.gamebook.io/alarm-stories/live/index.html?episode=https://exg-gb-beta.s3.eu-west-1.amazonaws.com/file-uploads/exports/62067cc476dc100013b819c6/621dd95c76dc100013b8287a/Chapter_1_87.zip"/>
    </div>
)

export default LSPage
 